import React, { useState, useEffect } from 'react'
import { Tooltip, message, Select } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
const { Option } = Select

export default function CounselorSession() {
  const [loading, setLoading] = useState(false)
  const [Isloading, setIsloading] = useState(false)
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const initialStudentId = queryParams.get('studentId') || ''

  const [formData, setFormData] = useState({
    studentId: initialStudentId, // Set initial value
    counselorId: userId,
    timeId: '',
  })
  const [availableTimes, setAvailableTimes] = useState([])
  const [errors, setErrors] = useState({})
  const [students, setStudents] = useState([])

  useEffect(() => {
    if (token) {
      fetchAvailableTimes()
    }
  }, [token])

  const fetchAvailableTimes = async () => {
    if (userId) {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/Session/Get-Two-Weeks-Counselor-AvailableDates?counselorId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (response.status === 200) {
          const filteredTimes = response.data.filter((time) => !time.isReserved)
          setAvailableTimes(filteredTimes)
        } else {
          message.error('Failed to fetch available times')
        }
      } catch (error) {
        console.error('Error fetching available times:', error)
        message.error('An error occurred while fetching available times')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchStudents()
  }, [])

  const fetchStudents = () => {
    fetch(
      `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Students?counselorId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStudents(data)
      })
      .catch((error) => console.error('Error fetching students:', error))
  }

  const handleChange = (value, option) => {
    setFormData({ ...formData, studentId: value })
    setErrors({ ...errors, studentId: '' }) // Clear error message on change
  }

  const handleSelectChange = (e) => {
    const selectedTimeId = e.target.value
    setFormData({ ...formData, timeId: selectedTimeId })
    setErrors({ ...errors, timeId: '' }) // Clear error message on change
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formErrors = {}

    if (!formData.studentId) {
      formErrors.studentId = 'Student ID is required'
    }
    if (!formData.timeId) {
      formErrors.timeId = 'Available time is required'
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    try {
      setIsloading(true)
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Session/Create-CounselorSession',
        {
          studentId: formData.studentId,
          counselorId: userId,
          timeId: formData.timeId,
        },
        {
          headers: headers,
        },
      )
      if (response.status === 200) {
        message.success('Session created successfully')
        setFormData({ studentId: '', counselorId: userId, timeId: '' })
      } else {
        message.error('Failed to add session')
      }
    } catch (error) {
      console.error('Error adding session:', error)
      message.error('An error occurred while adding the session')
    } finally {
      setIsloading(false)
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: 'relative' }}>
              <h5>Counselor Sessions</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label htmlFor="studentId" className="form-label" style={{ fontSize: 'large' }}>
                      Student Id
                    </label>
                    {errors.studentId && (
                      <div className="text-danger" style={{ fontSize: 'medium' }}>
                        {errors.studentId}
                      </div>
                    )}
                    <Select
                      id="studentIds"
                      style={{
                        borderColor: errors.studentId ? 'red' : '',
                        width: '100%',
                      }}
                      value={formData.studentId}
                      onChange={handleChange}
                    >
                      {students.map((student) => {
                        const isDisabled = !student.hasCounselorSessions

                        return (
                          <Option
                            key={student.studentId}
                            value={student.studentId}
                            disabled={isDisabled}
                          >
                            {isDisabled ? (
                              <Tooltip title="No remaining sessions">
                                <span>
                                  {student.studentId}, {student.name}
                                </span>
                              </Tooltip>
                            ) : (
                              `${student.studentId}, ${student.name}`
                            )}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label htmlFor="timeId" className="form-label" style={{ fontSize: 'large' }}>
                      Select Date Time
                    </label>
                    <select
                      className="form-control"
                      name="timeId"
                      value={formData.timeId}
                      onChange={handleSelectChange}
                      style={{ borderColor: errors.timeId ? 'red' : '' }}
                    >
                      <option value="">Select Time</option>
                      {availableTimes.map((time) => (
                        <option key={time.timeId} value={time.timeId}>
                          {new Date(time.availableTime).toLocaleString()}
                        </option>
                      ))}
                    </select>
                    {errors.timeId && (
                      <div className="text-danger" style={{ fontSize: 'medium' }}>
                        {errors.timeId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                    {Isloading ? 'Creating...' : 'Create Session'}
                    {Isloading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}
