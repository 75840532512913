import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import SoftInputRoot from 'src/components/SoftInput/SoftInputRoot'
import SoftInputWithIconRoot from 'src/components/SoftInput/SoftInputWithIconRoot'
import SoftInputIconBoxRoot from 'src/components/SoftInput/SoftInputIconBoxRoot'
import SoftInputIconRoot from 'src/components/SoftInput/SoftInputIconRoot'
import { useSoftUIController } from 'src/context'

const SoftInput = forwardRef(({ size, icon, error, success, disabled, ...rest }, ref) => {
  let template
  const [controller] = useSoftUIController()
  const { direction } = controller
  const iconDirection = icon && icon.direction

  if (icon && icon.component && iconDirection === 'left') {
    template = (
      <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
        <SoftInputIconBoxRoot ownerState={{ size }}>
          <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
            {icon.component}
          </SoftInputIconRoot>
        </SoftInputIconBoxRoot>
        <SoftInputRoot
          {...rest}
          ownerState={{ size, error, success, iconDirection, direction, disabled }}
        />
      </SoftInputWithIconRoot>
    )
  } else if (icon && icon.component && iconDirection === 'right') {
    template = (
      <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
        <SoftInputRoot
          {...rest}
          ownerState={{ size, error, success, iconDirection, direction, disabled }}
        />
        <SoftInputIconBoxRoot ownerState={{ size }}>
          <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
            {icon.component}
          </SoftInputIconRoot>
        </SoftInputIconBoxRoot>
      </SoftInputWithIconRoot>
    )
  } else {
    template = <SoftInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled }} />
  }

  return template
})

SoftInput.defaultProps = {
  size: 'medium',
  icon: {
    component: false,
    direction: 'none',
  },
  error: false,
  success: false,
  disabled: false,
}

SoftInput.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(['none', 'left', 'right']),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
}

SoftInput.displayName = 'SoftInput'

export default SoftInput
