import React, { useState, useMemo } from 'react'
// import './register.scss'
import 'react-international-phone/style.css'
import countryList from 'react-select-country-list'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

function useRegisterService() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    contactNumber: '',
    dateOfBirth: '',
    country: '',
    address: '',
    state: '',
    zip: '',
    city: '',
    gender: '',
    timeZone: '',
    race: '',
    experienceInYear: '',
    highestDegree: '',
    preferredSubject1: '',
    preferredSubject2: '',
    termsAndConditionsAccepted: true,
    role: '',
  })
  const options = useMemo(() => countryList().getData(), [])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const [formDataError, setFormDataError] = useState({
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    contactNumberError: '',
    dateOfBirthError: '',
    countryError: '',
    addressError: '',
    stateError: '',
    zipError: '',
    cityError: '',
    genderError: '',
    timeZoneError: '',
    raceError: '',
    experienceInYearError: '',
    highestDegreeError: '',
    preferredSubject1Error: '',
    preferredSubject2Error: '',
    roleError: '',
  })

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error('Event or target is undefined:', e)
      return
    }

    const { name, value } = e.target
    setFormDataError({ ...formDataError, [`${name}Error`]: '' })
    setFormData({ ...formData, [name]: value })
  }

  const handleRoleDropdownChange = (e) => {
    if (!e || !e.target) {
      setFormData({ ...formData, [name]: value })
    }
    const { name, value } = e.target
    setFormDataError({ ...formDataError, [`${name}Error`]: '' })
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      let hasError = false

      // Define required fields for each role
      const requiredFieldsForTutor = [
        'email',
        'firstName',
        'lastName',
        'contactNumber',
        'dateOfBirth',
        'country',
        'address',
        'state',
        'zip',
        'city',
        'gender',
        'timeZone',
        'race',
        'highestDegree',
        'preferredSubject1',
        'preferredSubject2',
        'experienceInYear',
        'termsAndConditionsAccepted',
        'role',
      ]

      const requiredFieldsForCounselor = [
        'address',
        'city',
        'contactNumber',
        'country',
        'dateOfBirth',
        'email',
        'firstName',
        'lastName',
        'gender',
        'race',
        'zip',
        'state',
        'timeZone',
        'termsAndConditionsAccepted',
        'role',
      ]

      // Optional fields for Tutor
      const optionalFieldsForTutor = ['middleName']
      const optionalFieldsForCounselor = ['middleName']

      // Determine required fields based on role
      const requiredFields =
        formData.role === 'Tutor'
          ? [...requiredFieldsForTutor, ...optionalFieldsForTutor]
          : [...requiredFieldsForCounselor, ...optionalFieldsForCounselor]

      // Validate required fields
      requiredFields.forEach((fieldName) => {
        if (
          typeof formData[fieldName] === 'string' &&
          !formData[fieldName].trim() &&
          !optionalFieldsForTutor.includes(fieldName)
        ) {
          const fieldNameWithSpaces = fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase())
          setFormDataError((prevState) => ({
            ...prevState,
            [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
          }))
          hasError = true
        }
      })

      if (hasError) {
        console.log('Validation errors:', formDataError)
        return
      }

      // Prepare filtered form data based on role
      const filteredFormData = requiredFields.reduce((acc, field) => {
        acc[field] = formData[field] !== undefined ? formData[field] : ''
        return acc
      }, {})

      console.log('Form data before submission:', filteredFormData)

      // Send request based on role
      const endpoint =
        formData.role === 'Tutor'
          ? 'https://soft.recipe4foodies.com/api/Account/Tutor-Register'
          : 'https://soft.recipe4foodies.com/api/Account/Counselor-Register'

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filteredFormData),
      })

      console.log('Response status:', response.status)

      if (response.ok) {
        const data = await response.json()
        console.log('Registration Successful:', data)
        toast.success('Registration Successful! Confirmation email sent', {
          autoClose: 5000,
          style: { background: 'lightgreen', color: 'white' },
        })

        // Redirect based on user role
        if (formData.role === 'Tutor') {
          navigate('/tutorLogin', {
            state: {
              successMessage: 'Registration Successful! Confirmation email sent',
            },
          })
        } else if (formData.role === 'Counselor') {
          navigate('/counselorLogin', {
            state: {
              successMessage: 'Registration Successful! Confirmation email sent',
            },
          })
        }
      } else {
        console.log('Registration failed with status:', response.status)
        try {
          const contentType = response.headers.get('content-type')
          if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json()
            console.error('Registration Error Data:', errorData)
          } else {
            const errorText = await response.text()
            console.error('Registration Error Text:', errorText)
          }
        } catch (error) {
          console.error('Error parsing error response:', error)
        }
        toast.error('This User is already Exist', {
          autoClose: 5000,
          style: { background: 'lightcoral', color: 'white' },
        })
      }
    } catch (error) {
      console.error('Unexpected Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   setIsLoading(true)

  //   try {
  //     let hasError = false

  //     // Define required fields for each role
  //     const requiredFieldsForTutor = [
  //       'email',
  //       'firstName',
  //       'lastName',
  //       'contactNumber',
  //       'dateOfBirth',
  //       'country',
  //       'address',
  //       'state',
  //       'zip',
  //       'city',
  //       'gender',
  //       'timeZone',
  //       'race',
  //       'highestDegree',
  //       'preferredSubject1',
  //       'preferredSubject2',
  //       'experienceInYear',
  //       'termsAndConditionsAccepted',
  //       'role',
  //     ]

  //     const requiredFieldsForCounselor = [
  //       'address',
  //       'city',
  //       'contactNumber',
  //       'country',
  //       'dateOfBirth',
  //       'email',
  //       'firstName',
  //       'lastName',
  //       'gender',
  //       'race',
  //       'zip',
  //       'state',
  //       'timeZone',
  //       'termsAndConditionsAccepted',
  //     ]

  //     // Optional fields for Tutor
  //     const optionalFieldsForTutor = ['middleName']
  //     const optionalFieldsForCounselor = ['middleName']

  //     // Determine required fields based on role
  //     const requiredFields =
  //       formData.role === 'Tutor'
  //         ? [...requiredFieldsForTutor, ...optionalFieldsForTutor]
  //         : [...requiredFieldsForCounselor, ...optionalFieldsForCounselor]

  //     // Validate required fields
  //     requiredFields.forEach((fieldName) => {
  //       if (
  //         typeof formData[fieldName] === 'string' &&
  //         !formData[fieldName].trim() &&
  //         !optionalFieldsForTutor.includes(fieldName)
  //       ) {
  //         const fieldNameWithSpaces = fieldName
  //           .replace(/([A-Z])/g, ' $1')
  //           .replace(/^./, (str) => str.toUpperCase())
  //         setFormDataError((prevState) => ({
  //           ...prevState,
  //           [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
  //         }))
  //         hasError = true
  //       }
  //     })

  //     if (hasError) {
  //       console.log('Validation errors:', formDataError)
  //       return
  //     }

  //     // Prepare filtered form data based on role
  //     const filteredFormData = requiredFields.reduce((acc, field) => {
  //       acc[field] = formData[field] !== undefined ? formData[field] : ''
  //       return acc
  //     }, {})

  //     console.log('Form data before submission:', filteredFormData)

  //     // Send request based on role
  //     const endpoint =
  //       formData.role === 'Tutor'
  //         ? 'https://soft.recipe4foodies.com/api/Account/Tutor-Register'
  //         : 'https://soft.recipe4foodies.com/api/Account/Counselor-Register'

  //     const response = await fetch(endpoint, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(filteredFormData),
  //     })

  //     console.log('Response status:', response.status)

  //     if (response.ok) {
  //       const data = await response.json()
  //       console.log('Registration Successful:', data)
  //       toast.success('Registration Successful! Confirmation email sent', {
  //         autoClose: 5000,
  //         style: { background: 'lightgreen', color: 'white' },
  //       })
  //     } else {
  //       console.log('Registration failed with status:', response.status)
  //       try {
  //         const contentType = response.headers.get('content-type')
  //         if (contentType && contentType.includes('application/json')) {
  //           const errorData = await response.json()
  //           console.error('Registration Error Data:', errorData)
  //         } else {
  //           const errorText = await response.text()
  //           console.error('Registration Error Text:', errorText)
  //         }
  //       } catch (error) {
  //         console.error('Error parsing error response:', error)
  //       }
  //       toast.error('This User is already Exist', {
  //         autoClose: 5000,
  //         style: { background: 'lightcoral', color: 'white' },
  //       })
  //     }
  //   } catch (error) {
  //     console.error('Unexpected Error:', error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  return {
    formData,
    formDataError,
    isLoading,
    handleSubmit,
    handleChange,
    handleRoleDropdownChange,
    setFormDataError,
    setFormData,
  }
}

export default useRegisterService
