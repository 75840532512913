import React, { useState, lazy, useEffect } from 'react'
import { Button, Table, Modal, message, Tooltip } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import './student.scss'
import { useNavigate } from 'react-router-dom'

const StudentInfo = lazy(() => import('./StudentChildComponent/StudentInfo'))
const StudentDetails = lazy(() => import('./StudentChildComponent/StudentDetails'))
const Session = lazy(() => import('./StudentChildComponent/Session'))
const Quiz = lazy(() => import('./StudentChildComponent/Quiz'))
const Assignment = lazy(() => import('./StudentChildComponent/Assignment'))
const Facts = lazy(() => import('./StudentChildComponent/Facts'))
const Recomendation = lazy(() => import('./StudentChildComponent/Recomendation'))
const Event = lazy(() => import('./StudentChildComponent/Event'))
const Subjects = lazy(() => import('./StudentChildComponent/Subjects'))
const Courses = lazy(() => import('./StudentChildComponent/Courses'))
const TargetCollege = lazy(() => import('./StudentChildComponent/TargetCollege'))

export default function Students() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [showDetails, setShowDetails] = useState(true)
  const [resultData, setData] = useState([])
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const [notesVisible, setNotesVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notesData, setNotesData] = useState([])

  const handleDocumentIconClick = (studentId) => {
    setSelectedStudentId(studentId)
    setShowDetails(false)
  }

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    handleDataSource()
  }, [])

  const handleDataSource = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `https://soft.recipe4foodies.com/api/CounselorStudents/GetStudentsByCounselor?counselorId=${userId}`,
        {
          headers: headers,
        },
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleNotesClose = () => {
    setNotesVisible(false)
    setNotesData([])
  }

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Parent ID',
      dataIndex: 'parentId',
      key: 'parentId',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Tutor Group Sessions',
      dataIndex: 'tutorGroupSessions',
      key: 'tutorGroupSessions',
    },
    {
      title: 'Tutor Single Sessions',
      dataIndex: 'tutorSingleSessions',
      key: 'tutorSingleSessions',
    },
    {
      title: 'Counselor Session',
      dataIndex: 'counselorSessions',
      key: 'counselorSessions',
    },
    {
      title: 'Create Session',
      key: 'createSession',
      render: (record) => {
        const hasCounselorSessions = record.counselorSessions > 0

        return (
          <Tooltip title={!hasCounselorSessions ? 'No counselor sessions left' : ''}>
            <Button
              type="primary"
              style={{ display: 'block', backgroundColor: '#0d6efd' }}
              onClick={() => handleCreateSession(record.studentId)}
              disabled={!hasCounselorSessions} // Disable if no counselor sessions left
            >
              Create Session
            </Button>
          </Tooltip>
        )
      },
    },
    {
      title: 'Info',
      dataIndex: 'studentId',
      key: 'info',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleDocumentIconClick(studentId)}>
          Info
        </Button>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'studentId',
      key: 'Notes',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleNotesClick(studentId)}>
          Notes
        </Button>
      ),
    },
    {
      title: 'Courses',
      dataIndex: 'studentId',
      key: 'Courses',
      render: (studentId) => (
        <Button type="primary" onClick={() => handleCourseClick(studentId)}>
          Courses
        </Button>
      ),
    },
  ]

  const handleNotesClick = (studentId) => {
    navigate(`/studentNotes/${studentId}`)
  }

  const handleCourseClick = (studentId) => {
    navigate(`/studentCourses/${studentId}`)
  }

  const [cardNumber, setCardNumber] = useState(null)
  const handleLinkClick = (linkId) => {
    setCardNumber(linkId)
  }

  const [showFirstSix, setShowFirstSix] = useState(true)

  const handleToggleClick = () => {
    setShowFirstSix(!showFirstSix)
  }

  const navigate = useNavigate()
  const handleCreateSession = (studentId) => {
    navigate(`/counselorSession?studentId=${studentId}`)
  }

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            {showDetails ? (
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <b>Students</b>
                </div>
                <div className="card-body">
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={resultData}
                    columns={columns}
                    loading={loading}
                  />
                </div>
              </div>
            ) : (
              <>
                <div style={{ marginLeft: '120px' }}>
                  <ul className="nav-links">
                    <>
                      <li className="center">
                        <a href="#" onClick={() => handleLinkClick(2)} title="Student Details">
                          Info
                        </a>
                      </li>
                      <li className="center">
                        <a href="#" onClick={() => handleLinkClick(1)} title="Student Info">
                          Details
                        </a>
                      </li>
                      <li className="center">
                        <a href="#" onClick={() => handleLinkClick(3)} title="Remaining Sessions">
                          Sessions
                        </a>
                      </li>
                      {/* <li className="center">
                          <a href="#" onClick={() => handleLinkClick(4)} title="Platform Quiz">
                            Quiz
                          </a>
                        </li> */}
                      {/* <li className="center">
                          <a
                            href="#"
                            onClick={() => handleLinkClick(5)}
                            title="Platform Assignment"
                          >
                            Assignment
                          </a>
                        </li> */}
                      {/* <li className="center">
                          <a href="#" onClick={() => handleLinkClick(6)} title="Student Facts">
                            Facts
                          </a>
                        </li> */}

                      <li className="center"></li>
                      <li className="center">
                        <a
                          href="#"
                          onClick={() => handleLinkClick(7)}
                          title="Sessions Recommendation"
                        >
                          Recommendations
                        </a>
                      </li>
                      <li className="center">
                        <a href="#" onClick={() => handleLinkClick(8)} title="Student Event">
                          Event
                        </a>
                      </li>
                      <li className="center">
                        <a href="#" onClick={() => handleLinkClick(9)} title="Subjects Taken">
                          Subjects
                        </a>
                      </li>
                      {/* <li className="center">
                          <a href="#" onClick={() => handleLinkClick(10)} title="Courses">
                            Courses
                          </a>
                        </li> */}
                      {/* <li className="center">
                          <a href="#" onClick={() => handleLinkClick(11)} title="Target Colleges">
                            Colleges
                          </a>
                        </li> */}
                    </>
                  </ul>
                </div>
                <div>
                  {cardNumber === 1 && <StudentInfo studentId={selectedStudentId} />}
                  {cardNumber === 2 && <StudentDetails studentId={selectedStudentId} />}
                  {cardNumber === 3 && <Session studentId={selectedStudentId} />}
                  {cardNumber === 4 && <Quiz studentId={selectedStudentId} />}
                  {cardNumber === 5 && <Assignment studentId={selectedStudentId} />}
                  {cardNumber === 6 && <Facts />}
                  {cardNumber === 7 && <Recomendation />}
                  {cardNumber === 8 && <Event studentId={selectedStudentId} />}
                  {cardNumber === 9 && <Subjects studentId={selectedStudentId} />}
                  {/* {cardNumber === 10 && <Courses />} */}
                  {cardNumber === 11 && <TargetCollege />}
                </div>
              </>
            )}
          </div>
        </div>
      </DashboardLayout>
      <Modal
        title="Student Notes"
        visible={notesVisible}
        onCancel={handleNotesClose}
        footer={[
          <Button key="close" onClick={handleNotesClose}>
            Close
          </Button>,
        ]}
      >
        <Table
          dataSource={notesData}
          columns={[
            {
              title: 'Note ID',
              dataIndex: 'noteId',
              key: 'noteId',
            },
            {
              title: 'Author',
              dataIndex: 'autherPerson',
              key: 'autherPerson',
            },
            {
              title: 'Date & Time',
              dataIndex: 'dateTime',
              key: 'dateTime',
            },
            {
              title: 'Content',
              dataIndex: 'content',
              key: 'content',
            },
          ]}
          rowKey="noteId"
        />
      </Modal>
    </>
  )
}
