import React from 'react'
// @mui material components
import Grid from '@mui/material/Grid'

import SoftBox from 'src/components/SoftBox/SoftBoxRoot'

import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout/index'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar/index'
import OneOnoneStudent from 'src/layouts/dashboard/components/oneOnoneStudent'
import GroupStudents from 'src/layouts/dashboard/components/Groupstudents'
import Meetings from './components/Meeting/meeting'

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Meetings />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}></Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <OneOnoneStudent />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <GroupStudents />
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  )
}

export default Dashboard
