import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table, Button, Modal, message, Tooltip } from 'antd'
import 'antd/dist/antd'
import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import moment from 'moment'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'

function Author({ name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  )
}

Author.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

const RequestedSession = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [requestedSessionsData, setRequestedSessionsData] = useState([])
  const [approveModalVisible, setApproveModalVisible] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [approving, setApproving] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (token) {
      fetchRequestedSessions()
    }
  }, [token, userId])

  const fetchRequestedSessions = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Session/Get-CounselorAccess-Requests-For-Counselor?counselorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setRequestedSessionsData(response.data) // Set your data in the state
    } catch (error) {
      console.error('Error fetching requested sessions:', error)
      message.error('Error fetching requested sessions')
    } finally {
      setLoading(false)
    }
  }

  const handleRequest = async (requestId, isApprove) => {
    setApproving(true) // Set loading state to true when starting the request
    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Session/CounselorAccess-Approve',
        {
          requestId: requestId,
          isApprove: isApprove,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.status === 200) {
        message.success(response.data)
        fetchRequestedSessions()
      } else {
        message.error(response.data) // Show error returned from the server
      }
    } catch (error) {
      const errorMsg = error.response?.data || 'Error handling request'
      if (errorMsg.includes("don't have Remaining Sessions")) {
        message.error('The student does not have any remaining sessions.')
      } else {
        console.error('Error handling request:', error)
        message.error(errorMsg)
      }
    } finally {
      setApproving(false) // Set loading state to false when the request completes
      setApproveModalVisible(false)
      setSelectedRequest(null)
    }
  }

  const showApproveModal = (record) => {
    setSelectedRequest(record)
    setApproveModalVisible(true)
  }

  const requestedSessionsColumns = [
    {
      title: '#',
      dataIndex: 'Number',
      key: 'Number',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Time',
      dataIndex: 'selectedDate',
      key: 'selectedDate',
      render: (text) => moment(text).format('MMM D, YYYY h:mm A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'Action',
      render: (text, record) => {
        const isPending = record.status === 'Pending'
        const isDenied = record.status === 'Denied'
        const isGranted = record.status === 'Granted'

        return (
          <div>
            {isDenied ? (
              <Tooltip title="Status Denied">
                <Button type="primary" disabled>
                  Approve
                </Button>
              </Tooltip>
            ) : isGranted ? (
              <Tooltip title="Status Approved">
                <Button type="primary" disabled>
                  Approve
                </Button>
              </Tooltip>
            ) : (
              <Button type="primary" onClick={() => showApproveModal(record)} disabled={!isPending}>
                Approve
              </Button>
            )}
            <span style={{ marginRight: '10px' }} />
            {isDenied ? (
              <Tooltip title="Status Denied">
                <Button type="primary" disabled>
                  Decline
                </Button>
              </Tooltip>
            ) : isGranted ? (
              <Tooltip title="Status Approved">
                <Button type="primary" disabled>
                  Decline
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                onClick={() => handleRequest(record.counselorAccessRequestId, false)}
                disabled={!isPending}
              >
                Decline
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <CCardBody>
              <CCard>
                <CCardHeader
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <b>Requested Sessions</b>
                </CCardHeader>
                <CCardBody>
                  <Table
                    className="table-responsive"
                    dataSource={requestedSessionsData}
                    columns={requestedSessionsColumns}
                    bordered
                    loading={loading}
                    pagination={false}
                  />
                </CCardBody>
              </CCard>
            </CCardBody>
            {selectedRequest && (
              <Modal
                title="Approve Request"
                open={approveModalVisible}
                onCancel={() => setApproveModalVisible(false)}
                footer={[
                  <Button key="cancel" onClick={() => setApproveModalVisible(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="approve"
                    type="primary"
                    onClick={() => handleRequest(selectedRequest.counselorAccessRequestId, true)}
                    loading={approving} // Show loading spinner when approving
                    disabled={approving} // Disable the button while loading
                  >
                    {approving ? 'Please wait...' : 'Approve'}
                  </Button>,
                ]}
              >
                <p>
                  <b>Student ID:</b> {selectedRequest.studentId}
                </p>
                <p>
                  <b>Time:</b> {new Date(selectedRequest.selectedDate).toLocaleString()}
                </p>
              </Modal>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default RequestedSession
