import React, { Suspense, lazy } from 'react'
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
//COUNSELOR CHAT IMPORT START
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Sidenav from 'src/examples/Sidenav'
import theme from 'src/assets/theme'
import routes from 'src/routes'
import { SoftUIControllerProvider, useSoftUIController } from 'src/context'
import brand from 'src/assets/images/journey.png'
import MultiLogin from '../src/AdminLayouts/MultiLogin'
import SetPassword from './StudentLayouts/register/SetPassword'
import Register from './Common/Register'
import ProtectedRoute from './Common/ProtectedRoute'
import TutorSetPassword from './TutorLayouts/TutorLogin/TutorSetPassword'
import CounselorSetPassword from './layouts/authentication/CounselorSetPassword'
import StudentNotes from './layouts/Students/StudentNotes'
import CounselorTutor from './layouts/CounselorTutors/CouneselorTutors'
import EmailConfirmation from './StudentLayouts/register/ConfirmationEmail'
import EmailVerification from './StudentLayouts/register/EmailVerification'
import Courses from './layouts/Students/Courses'

const UploadDocs = lazy(() => import('./layouts/UploadDocs/UploadDocs'))
const ProfileCounselor = lazy(() => import('./layouts/ProfileCounselor/ProfileCounselor'))
const ChangePassword = lazy(() => import('./layouts/authentication/changepassword'))
const ResetCounselorPassword = lazy(() => import('./layouts/authentication/resetPassword'))
const Improvingstudents = lazy(() => import('./layouts/GroupStudentimproving'))
const Groupsustainingstudents = lazy(() => import('./layouts/GroupStudentsustaining'))
const GroupDecliningStudent = lazy(() => import('./layouts/Groupstudentdeclining'))
const CounselorSession = lazy(() => import('./layouts/tables'))
const OneononeImprovingstudents = lazy(() => import('./layouts/1on1Studentimproving'))
const OneononeSustainingstudents = lazy(() => import('./layouts/1on1Studentsustaining'))
const OneononeDecliningStudent = lazy(() => import('./layouts/1on1Studentdeclining'))
const Login = lazy(() => import('src/StudentLayouts/login/Login'))
// const Register = lazy(() => import('src/Common/Register'))
const StdRegister = React.lazy(() => import('src/StudentLayouts/register/Register'))
const ForgotPassword = React.lazy(() => import('src/StudentLayouts/ForgotPassword/forgotpassword'))
const ResetPassword = React.lazy(() => import('src/StudentLayouts/resetPassword/ResetPassword'))
const TutorResetPassword = React.lazy(() => import('src/TutorLayouts/authentication/resetPassword'))
const CounselorResetPassword = React.lazy(() => import('./layouts/authentication/resetPassword'))

// Student,Library,Parent,Tutor Dashboard Contents
const AppStudentContent = lazy(() => import('../src/StudentLayouts/StudentContent/StudentContent'))
const LibraryContent = lazy(() => import('../src/LibraryLayouts/LibraryContent/LibraryContent'))
const AppParentContent = lazy(() => import('../src/ParentLayouts/ParentContent/ParentContent'))
const AppTutorContent = lazy(() => import('../src/TutorLayouts/TutorContent/TutorContent'))
const AppBAMContent = lazy(() => import('../src/BAMLayouts/BAMContent/BAMContent'))
const AdminContent = lazy(() => import('../src/AdminLayouts/MultiLogin'))
const Items = lazy(() => import('../src/LibraryLayouts/LibraryContent/Items'))
const SubSubjectItems = lazy(() => import('../src/LibraryLayouts/LibraryContent/SubSubjectItems'))
const StudentFillInfo = lazy(() => import('src/layouts/IntakeInterview/studentFillInfo'))
const TutorLogin = React.lazy(() => import('src/TutorLayouts/TutorLogin/TutorLogin'))
const CounselorLogin = React.lazy(() =>
  import('src/layouts/authentication/CounselorLogin/CounselorLogin'),
)

function AppCounselorContent() {
  const [controller] = useSoftUIController()
  const { layout, sidenavColor } = controller

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }

      return null
    })

  const showSidebar = ![
    '/studentregister',
    '/login',
    '/multiLogin',
    '/Register',
    '/tutorLogin',
    '/counselorLogin',
    '/changePassword',
    '/resetPasswordLogin',
    '/counselorResetPassword',
    '/forgotpassword',
  ].some((pattern) => {
    if (typeof pattern === 'string') {
      // eslint-disable-next-line no-restricted-globals
      return location.pathname === pattern
    } else if (pattern instanceof RegExp) {
      // eslint-disable-next-line no-restricted-globals
      return pattern.test(location.pathname)
    }
  })

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === 'dashboard' && showSidebar && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Counselor Dashboard"
              routes={routes}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/counselordashboard" />} />
          <Route path="/counselorLogin" name="Login" element={<CounselorLogin />} />
          <Route path="/Register" name="Register" element={<Register />} />
          <Route path="/studentregister" name="Register" element={<StdRegister />} />
          <Route path="/uploadDocs" name="Upload Docs Page" element={<UploadDocs />} />
          <Route path="/profileCounselor" name="Upload Docs Page" element={<ProfileCounselor />} />
          <Route path="/changePassword" name="Change Password" element={<ChangePassword />} />
          <Route path="/counselorSession" name="Counselor Session" element={<CounselorSession />} />
          <Route path="/items" name="Items" element={<Items />} />
          <Route path="/SubSubjectItems" element={<SubSubjectItems />} />
          <Route path="/studentFillInfo" element={<StudentFillInfo />} />
          <Route path="/counselorResetPassword" element={<CounselorResetPassword />} />{' '}
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/studentNotes/:studentId" element={<StudentNotes />} />
          <Route path="/studentCourses/:studentId" element={<Courses />} />
          <Route path="/counselorTutor" element={<CounselorTutor />} />
          <Route
            path="/multiLogin"
            element={<ProtectedRoute element={<MultiLogin />} allowedRoles={['Admin']} />}
          />
          <Route
            path="/1on1Improvingstudents"
            name="Improving Students"
            element={<OneononeImprovingstudents />}
          />
          <Route
            path="/1on1sustainingstudents"
            name="Sustaining Students"
            element={<OneononeSustainingstudents />}
          />
          <Route
            path="/1on1decliningstudents"
            name="Group Declining Students"
            element={<OneononeDecliningStudent />}
          />
          <Route
            path="/groupImprovingstudents"
            name="Improving Students"
            element={<Improvingstudents />}
          />
          <Route
            path="/groupsustainingstudents"
            name="Sustaining Students"
            element={<Groupsustainingstudents />}
          />
          <Route
            path="/groupdecliningstudents"
            name="Group Declining Students"
            element={<GroupDecliningStudent />}
          />
          <Route
            path="/resetCounselorPassword"
            name="Reset Counselor Password"
            element={<ResetCounselorPassword />}
          />
        </Routes>
      </ThemeProvider>
    </>
  )
}

const renderContent = () => {
  const userId = localStorage.getItem('userId')
  const userRole = localStorage.getItem('role')
  const proxyScreen = localStorage.getItem('AdminProxyScreen')

  if (userId === null || userId === '') {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <SoftUIControllerProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/tutorLogin" element={<TutorLogin />} />
              <Route path="/counselorLogin" element={<CounselorLogin />} />
              <Route path="/Register" name="Register" element={<Register />} />
              <Route path="/studentregister" element={<StdRegister />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/setPassword" element={<SetPassword />} />
              <Route path="/tutorSetPassword" element={<TutorSetPassword />} />
              <Route path="/counselorSetPassword" element={<CounselorSetPassword />} />
              <Route path="/resetPasswordLogin" element={<ResetPassword />} />
              <Route path="/tutorResetPassword" element={<TutorResetPassword />} />
              <Route path="/counselorResetPassword" element={<CounselorResetPassword />} />
              <Route path="/emailConfirmation" element={<EmailConfirmation />} />
              <Route path="/emailVerification" element={<EmailVerification />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </SoftUIControllerProvider>
        </BrowserRouter>
      </Suspense>
    )
  } else {
    let AppContent
    if (userRole === 'Tutor' || (userRole === 'Admin' && proxyScreen === 'Tutor')) {
      AppContent = <AppTutorContent />
    } else if (userRole === 'Student' || (userRole === 'Admin' && proxyScreen === 'Student')) {
      AppContent = <AppStudentContent />
    } else if (userRole === 'Parent' || (userRole === 'Admin' && proxyScreen === 'Parent')) {
      AppContent = <AppParentContent />
    } else if (userRole === 'Counselor' || (userRole === 'Admin' && proxyScreen === 'Counselor')) {
      AppContent = <AppCounselorContent />
    } else if (userRole === 'Librarian' || (userRole === 'Admin' && proxyScreen === 'Librarian')) {
      AppContent = <LibraryContent />
    } else if (userRole === 'BAMAdmin' || (userRole === 'Admin' && proxyScreen === 'BAMAdmin')) {
      AppContent = <AppBAMContent />
    } else if (userRole === 'Admin') {
      AppContent = <AdminContent />
    }

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <SoftUIControllerProvider>
            <Routes>
              <Route path="/setPassword" element={<SetPassword />} />
              <Route path="/tutorSetPassword" element={<TutorSetPassword />} />
              <Route path="/counselorSetPassword" element={<CounselorSetPassword />} />
              <Route path="*" element={AppContent} />
              <Route path="/login" element={<Login />} />
              <Route path="/Register" name="Register" element={<Register />} />
              <Route path="/tutorLogin" element={<TutorLogin />} />
              <Route path="/counselorLogin" element={<CounselorLogin />} />{' '}
              <Route path="/resetPasswordLogin" element={<ResetPassword />} />
              <Route path="/tutorResetPassword" element={<TutorResetPassword />} />
              <Route path="/counselorResetPassword" element={<CounselorResetPassword />} />
              <Route path="/emailConfirmation" element={<EmailConfirmation />} />
              <Route path="/emailVerification" element={<EmailVerification />} />
            </Routes>
          </SoftUIControllerProvider>
        </BrowserRouter>
      </Suspense>
    )
  }
}

export default function App() {
  return <>{renderContent()}</>
}

// const renderContent = () => {
//   const userId = localStorage.getItem('userId')
//   if (userId === null || userId === '') {
//     return (
//       <Suspense fallback={<div>Loading...</div>}>
//         <BrowserRouter>
//           <SoftUIControllerProvider>
//             {/* <Login /> */}
//             <Routes>
//               <Route path="/login" element={<Login />} />
//               <Route path="/studentregister" element={<StdRegister />} />
//               <Route path="*" element={<Login />} />
//               <Route path="/forgotpassword" element={<ForgotPassword />} />
//               <Route path="/setPassword" element={<SetPassword />} />
//             </Routes>
//           </SoftUIControllerProvider>
//         </BrowserRouter>
//       </Suspense>
//     )
//   } else {
//     const userRole = localStorage.getItem('role')
//     const proxyScreen = localStorage.getItem('AdminProxyScreen')
//     if (userRole === 'Tutor' || (userRole === 'Admin' && proxyScreen === 'Tutor')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AppTutorContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'Student' || (userRole === 'Admin' && proxyScreen === 'Student')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AppStudentContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'Parent' || (userRole === 'Admin' && proxyScreen === 'Parent')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AppParentContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'Counselor' || (userRole === 'Admin' && proxyScreen === 'Counselor')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AppCounselorContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'Librarian' || (userRole === 'Admin' && proxyScreen === 'Librarian')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <LibraryContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'BAMAdmin' || (userRole === 'Admin' && proxyScreen === 'BAMAdmin')) {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AppBAMContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     } else if (userRole === 'Admin') {
//       return (
//         <Suspense fallback={<div>Loading...</div>}>
//           <BrowserRouter>
//             <SoftUIControllerProvider>
//               <AdminContent />
//             </SoftUIControllerProvider>
//           </BrowserRouter>
//         </Suspense>
//       )
//     }
//   }
// }

// export default function App() {
//   return <>{renderContent()}</>
// }
