/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from '@mui/material/styles'

export default styled('div')(({ theme, ownerState }) => {
  const { palette, functions, borders } = theme
  const { error, success, disabled } = ownerState

  const { inputColors, grey, white } = palette
  const { pxToRem } = functions
  const { borderRadius, borderWidth } = borders

  // border color value
  let borderColorValue = inputColors.borderColor.main

  if (error) {
    borderColorValue = inputColors.error
  } else if (success) {
    borderColorValue = inputColors.success
  }

  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: disabled ? grey[200] : white.main,
    border: `${borderWidth[1]} solid`,
    borderRadius: borderRadius.md,
    borderColor: borderColorValue,

    '& .MuiInputBase-input': {
      height: pxToRem(20),
    },
  }
})
