import React from 'react'
import Card from '@mui/material/Card'

import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'

import Grid from '@mui/material/Grid'

import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'

// Data
import authorsTableData from 'src/layouts/tables/data/Sessions'
import Table from 'src/examples/Tables/Table'
import Session from 'src/layouts/tables/data/Sessions'

function Tables() {
  const { columns, rows } = authorsTableData

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Session />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  )
}

export default Tables
