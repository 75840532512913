import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import 'src/Common/Register.scss'
import loginImages from 'src/assets/images/images.png'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import countryList from 'react-select-country-list'
import { CFormInput, CInputGroup } from '@coreui/react'
import usStates from 'united-states'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useRegisterService from 'src/Common/Registerservice'
import Termandcondition from '../assets/termandcondition/termandcondition.pdf'
import { Tooltip } from 'antd'
import axios from 'axios'

const Register = () => {
  const {
    formData,
    formDataError,
    isLoading,
    handleSubmit,
    handleChange,
    handleRoleDropdownChange,
    setFormDataError,
    setFormData,
  } = useRegisterService()
  const [isRoleSelected, setIsRoleSelected] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [courses, setCourses] = useState([])

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }
  const options = useMemo(() => countryList().getData(), [])

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const success = await handleSubmit()
      if (success) {
        toast.success('Registration Successful! Confirmation email sent to you', {
          autoClose: 5000,
          style: { background: 'lightgreen', color: 'white' },
        })
      }
    } catch (error) {
      toast.error(error.message || 'Registration Error', {
        autoClose: 5000,
        style: { background: 'lightcoral', color: 'white' },
      })
    }
  }

  const handlePdfPreview = async (fileUrl) => {
    try {
      const extension = fileUrl.split('.').pop().toLowerCase()

      if (extension === 'pdf') {
        window.open(fileUrl, '_blank')
      } else {
        window.open(fileUrl, '_blank')
      }
    } catch (error) {
      console.error('Error handling file:', error)
    }
  }

  const handleRoleChange = (e) => {
    handleRoleDropdownChange(e)
    setIsRoleSelected(e.target.value !== '')
  }

  useEffect(() => {
    console.log('useEffect running')
    fetchCourses()
  }, [])

  const fetchCourses = async () => {
    try {
      console.log('Fetching courses...')
      const response = await axios.get('https://soft.recipe4foodies.com/api/Course/Get-Course-List')
      console.log('Courses data:', response.data)
      setCourses(response.data)
    } catch (error) {
      console.error('Error fetching courses:', error.message || error)
    }
  }

  return (
    <div
      className="container-fluid"
      style={{
        background: 'linear-gradient(to right bottom, rgb(6 88 92) 0%, rgb(58 171 32) 100%)',
        color: 'white',
      }}
    >
      <div className="container">
        <form className="p-4" style={{ border: 'none' }} onSubmit={onSubmit}>
          <div className="d-flex justify-content-center">
            <h6 style={{ fontSize: '2rem', fontFamily: 'system-ui', fontWeight: '700' }}>
              Role Base Registration
            </h6>
          </div>
          <p className="text-medium-emphasis text-size">
            <img src={loginImages} alt="" className="picd" />
            <span style={{ color: 'white' }}>
              <b>Create</b> your account
            </span>
          </p>

          <div className="container-fluid">
            <ToastContainer />
            <div className="row">
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>Select Role *</label>
                <CInputGroup className="mb-3 textregisterbox">
                  <select
                    aria-label=".form-select-sm example"
                    name="role"
                    value={formData.role}
                    onChange={handleRoleChange}
                    className={`form-select form-select-sm ${
                      formDataError.roleError ? 'is-invalid' : ''
                    }`}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    <option value="Tutor">I am a Tutor</option>
                    <option value="Counselor">I am a Counselor</option>
                  </select>
                  {formDataError.roleError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.roleError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>First Name *</label>
                <CInputGroup>
                  <CFormInput
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={`form-control mb-4 ${
                      formDataError.firstNameError ? 'is-invalid' : ''
                    }`}
                    placeholder="Enter First Name"
                  />
                  {formDataError.firstNameError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.firstNameError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Middle Name
                  <span className="greenstariccolor">*</span>
                </label>
                <CInputGroup>
                  <CFormInput
                    placeholder="Enter Middle name"
                    name="middleName"
                    value={formData.middleName}
                    onChange={handleChange}
                    className="form-control"
                  />
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Last Name *
                  {/* <span className={`stariccolor ${studentLastName ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <CFormInput
                    placeholder="Enter Last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={`form-control mb-4 ${
                      formDataError.lastNameError ? 'is-invalid' : ''
                    }`}
                  />
                  {formDataError.lastNameError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.lastNameError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Mobile Number *
                  {/* <span className={`stariccolor ${studentMobileNumber ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <PhoneInput
                    name="contactNumber"
                    placeholder="Enter Contact Number"
                    value={formData.contactNumber}
                    defaultCountry="us"
                    inputStyle={{ width: '285px' }}
                    onChange={(value) => handleChange(value, 'contactNumber')}
                    onBlur={handleChange}
                    className={`col-md-12 ${formDataError.contactNumberError ? 'is-invalid' : ''}`}
                  />

                  {formDataError.contactNumberError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.contactNumberError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Date of Birth *
                  {/* <span className={`stariccolor ${studentDOB ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <input
                    type="date"
                    placeholder="Enter Date of Birth"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    className={`form-control mb-4 ${
                      formDataError.dateOfBirthError ? 'is-invalid' : ''
                    }`}
                  />
                  {formDataError.dateOfBirthError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.dateOfBirthError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Email *
                  {/* <span className={`stariccolor ${studentEmail ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <CFormInput
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    className={`form-control mb-4 ${formDataError.emailError ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                  />
                  {formDataError.emailError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.emailError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Address *
                  {/* <span className={`stariccolor ${address ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <CFormInput
                    placeholder="Enter address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={`form-control mb-4 ${
                      formDataError.addressError ? 'is-invalid' : ''
                    }`}
                  />
                  {formDataError.addressError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.addressError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  City *
                  {/* <span className={`stariccolor ${postalCode ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <CFormInput
                    placeholder="Enter City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={`form-control mb-4 ${formDataError.cityError ? 'is-invalid' : ''}`}
                  />
                  {formDataError.cityError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.cityError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                {' '}
                <label style={{ fontSize: 'medium' }}>State *</label>
                <CInputGroup>
                  <select
                    aria-label=".form-select-sm example"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={`form-control ${formDataError.stateError ? 'is-invalid' : ''}`}
                  >
                    <option value="" disabled>
                      Please select State
                    </option>
                    {usStates.map((state) => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formDataError.stateError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.stateError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {' '}
                <label style={{ fontSize: 'medium' }}>Country *</label>
                <CInputGroup>
                  <select
                    className={`form-select form-select-sm ${
                      formDataError.countryError ? 'is-invalid' : ''
                    }`}
                    aria-label=".form-select-sm example"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Please Select Country
                    </option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {formDataError.countryError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.countryError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Zip Code *
                  {/* <span className={`stariccolor ${postalCode ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup>
                  <CFormInput
                    placeholder="Enter Zip Code"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    className={`form-control mb-4 ${formDataError.zipError ? 'is-invalid' : ''}`}
                  />
                  {formDataError.zipError && (
                    <div className="invalid-feedback invalid-message">{formDataError.zipError}</div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Gender *
                  {/* <span className={`stariccolor ${gender ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup className="mb-3 textregisterbox">
                  <select
                    className={`form-select form-select-sm ${
                      formDataError.genderError ? 'is-invalid' : ''
                    }`}
                    aria-label=".form-select-sm example"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="UnSpecified">UnSpecified</option>
                    <option value="Do not wish to disclosed">Do not wish to disclosed</option>
                  </select>
                  {formDataError.genderError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.genderError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Us TimeZone *
                  {/* <span className={`stariccolor ${race ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup className="mb-3 textregisterbox">
                  <select
                    className={`form-select form-select-sm ${
                      formDataError.timeZoneError ? 'is-invalid' : ''
                    }`}
                    aria-label=".form-select-sm example"
                    name="timeZone"
                    value={formData.timeZone}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Timezone
                    </option>
                    <option value="Eastern Standard Time (EST)">Eastern Standard Time (EST)</option>
                    <option value="Central Standard Time (CST)">Central Standard Time (CST)</option>
                    <option value="Mountain Standard Time (MST)">
                      Mountain Standard Time (MST)
                    </option>
                    <option value="Pacific Standard Time (PST)">Pacific Standard Time (PST)</option>
                    <option value="Hawaii-Aleutian Standard Time (HST)">
                      Hawaii-Aleutian Standard Time (HST)
                    </option>
                    <option value="Alaskan Standard Time (AKST)">
                      Alaskan Standard Time (AKST)
                    </option>
                  </select>
                  {formDataError.timeZoneError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.timeZoneError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              <div className="col-md-4">
                <label style={{ fontSize: 'medium' }}>
                  Race *{/* <span className={`stariccolor ${race ? 'green' : 'red'}`}>*</span> */}
                </label>
                <CInputGroup className="mb-3 textregisterbox">
                  <select
                    className={`form-select form-select-sm ${
                      formDataError.raceError ? 'is-invalid' : ''
                    }`}
                    aria-label=".form-select-sm example"
                    name="race"
                    value={formData.race}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Race
                    </option>
                    <option value="White or Caucasian">White or Caucasian</option>
                    <option value="Black">Black</option>
                    <option value="Latino or Hispanic">Latino or Hispanic</option>
                    <option value="Asian">Asian</option>
                    <option value="Pacific Islander or Hawaiin">Pacific Islander or Hawaiin</option>
                    <option value="American Indian">American Indian</option>
                    <option value="Do not Disclosed">Do not Disclosed</option>
                  </select>
                  {formDataError.raceError && (
                    <div className="invalid-feedback" style={{ color: 'red', fontSize: 'large' }}>
                      {formDataError.raceError}
                    </div>
                  )}
                </CInputGroup>
              </div>
              {formData.role == 'Tutor' && (
                <div className="col-md-4">
                  <label style={{ fontSize: 'medium' }}>Highest Degree *</label>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="text"
                      name="highestDegree"
                      value={formData.highestDegree}
                      onChange={handleChange}
                      className={`form-control mb-4 ${
                        formDataError.highestDegreeError ? 'is-invalid' : ''
                      }`}
                      placeholder="Highest Degree"
                    />
                    {formDataError.highestDegreeError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.highestDegreeError}
                      </div>
                    )}
                  </CInputGroup>
                </div>
              )}
            </div>
            <div className="row">
              {formData.role == 'Tutor' && (
                <div className="col-md-4">
                  <label style={{ fontSize: 'medium' }}>Preferred Subject 1 *</label>
                  <CInputGroup>
                    <select
                      className={`form-select form-select-sm mb-4 ${
                        formDataError.preferredSubject1Error ? 'is-invalid' : ''
                      }`}
                      name="preferredSubject1"
                      onChange={handleChange}
                      value={formData.preferredSubject1}
                    >
                      <option value="">Please Select</option>
                      {courses.map((course) => (
                        <option key={course.courseId} value={course.courseName}>
                          {course.courseName}
                        </option>
                      ))}
                    </select>
                    {formDataError.preferredSubject1Error && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.preferredSubject1Error}
                      </div>
                    )}
                  </CInputGroup>
                </div>
              )}
              {formData.role == 'Tutor' && (
                <div className="col-md-4">
                  <label style={{ fontSize: 'medium' }}>Preferred Subject 2</label>
                  <CInputGroup>
                    <select
                      className={`form-select form-select-sm mb-4 ${
                        formDataError.preferredSubject2Error ? 'is-invalid' : ''
                      }`}
                      name="preferredSubject2"
                      onChange={handleChange}
                      value={formData.preferredSubject2}
                    >
                      <option value="">Please Select</option>
                      {courses.map((course) => (
                        <option key={course.courseId} value={course.courseName}>
                          {course.courseName}
                        </option>
                      ))}
                    </select>
                    {formDataError.preferredSubject2Error && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.preferredSubject2Error}
                      </div>
                    )}
                  </CInputGroup>
                </div>
              )}
              {formData.role == 'Tutor' && (
                <div className="col-md-4">
                  <label style={{ fontSize: 'medium' }}>
                    Experience In Years *
                    {/* <span className={`stariccolor ${studentFirstName ? 'green' : 'red'}`}>*</span> */}
                  </label>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="number"
                      name="experienceInYear"
                      value={formData.experienceInYear}
                      onChange={handleChange}
                      className={`form-control mb-4 ${
                        formDataError.experienceInYearError ? 'is-invalid' : ''
                      }`}
                      placeholder="Experience In Years"
                    />
                    {formDataError.experienceInYearError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.experienceInYearError}
                      </div>
                    )}
                  </CInputGroup>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="custom-checkbox-label">
                  <input
                    type="checkbox"
                    className="term-condition-checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  Accept &nbsp;
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault() // Prevent default link behavior
                      handlePdfPreview(Termandcondition) // Open PDF in a new tab
                    }}
                    style={{ textDecoration: 'none', color: 'orange' }}
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <div className="col-md-8 d-flex justify-content-end">
                {isRoleSelected ? (
                  <Link
                    to={formData.role === 'Tutor' ? '/tutorLogin' : '/counselorLogin'}
                    className="btn btn-link btncolor"
                  >
                    Already have an account/{formData.role}?
                  </Link>
                ) : (
                  <Tooltip title="Please Select Role">
                    <span className="disabled" style={{ color: 'orange', cursor: 'not-allowed' }}>
                      Already have an account?
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
            <br></br>
            <div className="col-md-12 d-flex justify-content-end" style={{ marginTop: '-20px' }}>
              <button
                className="btn"
                style={{ backgroundColor: 'blueviolet', color: 'white' }}
                onClick={handleSubmit}
                disabled={!isChecked}
              >
                {isLoading ? 'Loading...' : 'Create Account'}
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register
