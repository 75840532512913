import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './multilogin.scss'
import studentImage from 'src/assets/images/groupstudy.jpg'
import parentImage from 'src/assets/images/groupstudy.jpg'
import counselorImage from 'src/assets/images/Counseling.jpg'
import tutorImage from 'src/assets/images/on1tutoring.jpg'
import librarianImage from 'src/assets/images/Counseling.jpg'
import journeyImage from 'src/assets/images/1journey.png'
import bam from 'src/assets/images/bussiness.jpg'

const MultiLogin = () => {
  const navigate = useNavigate()

  const handleRedirect = async (event) => {
    event.preventDefault()
    const attributeValue = event.target.getAttribute('data-value')
    const emailMap = {
      Student: 'student@yopmail.com',
      Parent: 'parent@yopmail.com',
      Counselor: 'counselor@gmail.com',
      Tutor: 'tutor@gmail.com',
      Librarian: 'librarian@gmail.com',
      BAMAdmin: 'bamadmin@gmail.com',
    }
    const password = 'password'

    try {
      const response = await axios.post('https://soft.recipe4foodies.com/api/Account/Login', {
        email: emailMap[attributeValue],
        password: password,
      })

      const { token, userId, roles } = response.data

      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('roles', JSON.stringify(roles))
      localStorage.setItem('AdminProxyScreen', attributeValue)

      navigate(`/${attributeValue.toLowerCase()}Dashboard`)
      window.location.reload()
    } catch (error) {
      console.error('Login error:', error)
      // Handle login error appropriately
    }
  }

  const handleLogout = () => {
    localStorage.setItem('logout', true)
    localStorage.clear()
    navigate('/login')
  }
  return (
    <div className="multilogin-container">
      <div className="logout-button">
        <button className="btn btn-primary" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="header-image">
        <img src={journeyImage} alt="Journey" style={{ width: '50%' }} />
      </div>
      <div className="tile-container">
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${studentImage})`, cursor: 'pointer' }}
          data-value="Student"
        >
          <h3 data-value="Student">Student</h3>
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${parentImage})`, cursor: 'pointer' }}
          data-value="Parent"
        >
          <h3 data-value="Parent">Parent</h3>
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${counselorImage})`, cursor: 'pointer' }}
          data-value="Counselor"
        >
          <h3 data-value="Counselor">Counselor</h3>
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${tutorImage})`, cursor: 'pointer' }}
          data-value="Tutor"
        >
          <h3 data-value="Tutor">Tutor</h3>
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${librarianImage})`, cursor: 'pointer' }}
          data-value="Librarian"
        >
          <h3 data-value="Librarian">Library</h3>
        </div>
        <div
          className="tile"
          data-value="BAMAdmin"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${bam})`, cursor: 'pointer' }}
        >
          <h3 data-value="BAMAdmin">BAM</h3>
        </div>
      </div>
    </div>
  )
}

export default MultiLogin

// import React from 'react'
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import './multilogin.scss'
// import studentImage from 'src/assets/images/groupstudy.jpg'
// import parentImage from 'src/assets/images/groupstudy.jpg'
// import counselorImage from 'src/assets/images/Counseling.jpg'
// import tutorImage from 'src/assets/images/on1tutoring.jpg'
// import librarianImage from 'src/assets/images/Counseling.jpg'
// import journeyImage from 'src/assets/images/1journey.png'
// import bam from 'src/assets/images/bussiness.jpg'

// const MultiLogin = () => {
//   const navigate = useNavigate()

//   const handleRedirect = async (event) => {
//     const attributeValue = event.target.getAttribute('data-value')
//     const emailMap = {
//       Student: 'student@yopmail.com',
//       Parent: 'parent@yopmail.com',
//       Counselor: 'counselor@gmail.com',
//       Tutor: 'tutor@gmail.com',
//       Librarian: 'librarian@gmail.com',
//       BAMAdmin: 'bamadmin@gmail.com',
//     }
//     const password = 'password'

//     try {
//       const response = await axios.post('https://soft.recipe4foodies.com/api/Account/Login', {
//         email: emailMap[attributeValue],
//         password: password,
//       })

//       const { token, userId, roles } = response.data

//       localStorage.setItem('token', token)
//       localStorage.setItem('userId', userId)
//       localStorage.setItem('roles', JSON.stringify(roles))
//       localStorage.setItem('AdminProxyScreen', attributeValue)

//       navigate(`/${attributeValue.toLowerCase()}Dashboard`)
//       window.location.reload()
//     } catch (error) {
//       console.error('Login error:', error)
//       // Handle login error appropriately
//     }
//   }

//   const handleLogout = () => {
//     localStorage.setItem('logout', true)
//     localStorage.clear()
//     navigate('/login')
//   }
//   const handleRegister = () => {
//     navigate('/Register')
//   }

//   return (
//     <div className="multilogin-container">
//       <div className="logout-button">
//         <button className="btn btn-success" onClick={handleRegister}>
//           Tutor and Counselor Registration
//         </button>
//         &nbsp;
//         <button className="btn btn-primary" onClick={handleLogout}>
//           Logout
//         </button>
//       </div>
//       <div className="header-image">
//         <img src={journeyImage} alt="Journey" />
//       </div>
//       <div className="tile-container">
//         <div className="tile">
//           <img src={studentImage} alt="Student" className="tile-image" />
//           <h3>Student</h3>
//           <button className="btn btn-primary" data-value="Student" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//         <div className="tile">
//           <img src={parentImage} alt="Parent" className="tile-image" />
//           <h3>Parent</h3>
//           <button data-value="Parent" className="btn btn-primary" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//         <div className="tile">
//           <img src={counselorImage} alt="Counselor" className="tile-image" />
//           <h3>Counselor</h3>
//           <button data-value="Counselor" className="btn btn-primary" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//         <div className="tile">
//           <img src={tutorImage} alt="Tutor" className="tile-image" />
//           <h3>Tutor</h3>
//           <button data-value="Tutor" className="btn btn-primary" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//         <div className="tile">
//           <img src={librarianImage} alt="Librarian" className="tile-image" />
//           <h3>Librarian</h3>
//           <button data-value="Librarian" className="btn btn-primary" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//         <div className="tile">
//           <img src={bam} alt="BAMAdmin" className="tile-bam-image" />
//           <h3>BAM</h3>
//           <button data-value="BAMAdmin" className="btn btn-primary" onClick={handleRedirect}>
//             Go to Dashboard
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default MultiLogin
