import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'

export default function Notes() {
  const [isLoading, setIsLoading] = useState(false)
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}

  const [form] = Form.useForm()

  const handleSubmit = async (values) => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    try {
      setIsLoading(true)
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Note',
        {
          studentId: values.studentId,
          autherId: userId,
          dateTime: values.dateTime,
          content: values.content,
        },
        {
          headers: headers,
        },
      )
      if (response.status === 200) {
        message.success('Note added successfully')
        form.resetFields()
        setIsLoading(false)
      } else {
        message.error('Failed to add note')
      }
    } catch (error) {
      console.error('Error adding note:', error)
      message.error('An error occurred while adding the note')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: 'relative' }}>
              <h5>Session Notes</h5>
            </div>
            <div className="card-body">
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                  studentId: '',
                  dateTime: null,
                  content: '',
                }}
              >
                <Form.Item
                  label="Student Id"
                  name="studentId"
                  rules={[{ required: true, message: 'Please enter the student ID' }]}
                >
                  <Input placeholder="Enter Student Id here" />
                </Form.Item>

                <Form.Item
                  label="Set Date Time"
                  name="dateTime"
                  rules={[{ required: true, message: 'Please select a date and time' }]}
                >
                  <Input
                    type="datetime-local"
                    className="form-control"
                    placeholder="Enter Date Time here"
                    onChange={(e) => form.setFieldsValue({ dateTime: e.target.value })}
                  />
                </Form.Item>

                <Form.Item
                  label="Notes"
                  name="content"
                  rules={[{ required: true, message: 'Please add your notes' }]}
                >
                  <Input.TextArea rows={3} placeholder="Add Notes" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '15%',
                      maxWidth: '200px',
                      minWidth: '150px', // Ensures a minimum width on smaller screens
                      padding: '10px 20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {isLoading ? 'Please Wait...' : 'Submit'}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

// import React, { useState } from 'react'
// import { Table, message } from 'antd'
// import 'antd/dist/antd'
// import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
// import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
// import axios from 'axios'

// export default function Notes() {
//   const userId = localStorage.getItem('userId') || ''
//   const token = localStorage.getItem('token')
//   const headers = {}
//   const [formData, setFormData] = useState({
//     studentId: '',
//     dateTime: '',
//     content: '',
//   })

//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setFormData({ ...formData, [name]: value })
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     if (token) {
//       headers['Authorization'] = `Bearer ${token}`
//     }
//     try {
//       const response = await axios.post(
//         'https://soft.recipe4foodies.com/api/Note',
//         {
//           studentId: formData.studentId,
//           autherId: userId,
//           dateTime: formData.dateTime,
//           content: formData.content,
//         },
//         {
//           headers: headers,
//         },
//       )
//       if (response.status === 200) {
//         message.success('Note added successfully')
//         setFormData({ studentId: '', dateTime: '', content: '' }) // Reset the form after submission
//       } else {
//         message.error('Failed to add note')
//       }
//     } catch (error) {
//       console.error('Error adding note:', error)
//       message.error('An error occurred while adding the note')
//     }
//   }

//   return (
//     <DashboardLayout>
//       <div className="wrapper d-flex flex-column min-vh-100 bg-light">
//         <DashboardNavbar />
//         <div className="body flex-grow-1 px-3">
//           <div className="card">
//             <div className="card-header" style={{ position: 'relative' }}>
//               <h5>Session Notes</h5>
//             </div>
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-md-6 mb-3">
//                   <div className="mb-3">
//                     <label htmlFor="studentId" className="form-label" style={{ fontSize: 'large' }}>
//                       Student Id
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Enter Student Id here"
//                       name="studentId"
//                       value={formData.studentId}
//                       onChange={handleChange}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-md-6 mb-3">
//                   <div className="mb-3">
//                     <label htmlFor="dateTime" className="form-label" style={{ fontSize: 'large' }}>
//                       Set Date Time
//                     </label>
//                     <input
//                       type="datetime-local"
//                       className="form-control"
//                       placeholder="Enter Date Time here"
//                       name="dateTime"
//                       value={formData.dateTime}
//                       onChange={handleChange}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="content" className="form-label" style={{ fontSize: 'large' }}>
//                     Notes
//                   </label>
//                   <textarea
//                     className="form-control"
//                     id="content"
//                     rows="3"
//                     placeholder="Add Notes"
//                     name="content"
//                     value={formData.content}
//                     onChange={handleChange}
//                   ></textarea>
//                 </div>
//                 <div>
//                   <button
//                     style={{ width: '15%' }}
//                     className="btn btn-primary"
//                     onClick={handleSubmit}
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </DashboardLayout>
//   )
// }
