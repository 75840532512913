import React, { useState, useEffect } from 'react'
import { Select, Spin, Table } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { FilePdfFilled, UploadOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'

const { Option } = Select

export default function Syllabus() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [subjects, setSubjects] = useState([])
  const [subSubjects, setSubSubjects] = useState([])
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedSubSubject, setSelectedSubSubject] = useState(null)
  const [libraryItems, setLibraryItems] = useState([])
  const [loadingSubjects, setLoadingSubjects] = useState(false)
  const [loadingSubSubjects, setLoadingSubSubjects] = useState(false)
  const [loadingLibraryItems, setLoadingLibraryItems] = useState(false)

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    fetchSubjects()
  }, [])

  const fetchSubjects = () => {
    setLoadingSubjects(true)
    fetch('https://soft.recipe4foodies.com/api/Library/Get-Subjects', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubjects(data)
        setLoadingSubjects(false)
      })
      .catch((error) => {
        console.error('Error fetching subjects:', error)
        setLoadingSubjects(false)
      })
  }

  const fetchSubSubjects = (subjectId) => {
    setLoadingSubSubjects(true)
    fetch(`https://soft.recipe4foodies.com/api/Library/Get-SubSubjects?subjectId=${subjectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubSubjects(data)
        setLoadingSubSubjects(false)
      })
      .catch((error) => {
        console.error('Error fetching sub-subjects:', error)
        setLoadingSubSubjects(false)
      })
  }

  const fetchLibraryItemsForSubject = (subjectId) => {
    setLoadingLibraryItems(true)
    fetch(
      `https://soft.recipe4foodies.com/api/Library/Get-Subject-LibraryItems?subjectId=${subjectId}&itemType=Syllabus`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setLibraryItems(data)
        setLoadingLibraryItems(false)
      })
      .catch((error) => {
        console.error('Error fetching library items for subject:', error)
        setLoadingLibraryItems(false)
      })
  }

  const fetchLibraryItemsForSubSubject = (subSubjectId) => {
    setLoadingLibraryItems(true)
    fetch(
      `https://soft.recipe4foodies.com/api/Library/Get-SubSubject-LibraryItems?subSubjectId=${subSubjectId}&itemType=Syllabus`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setLibraryItems(data)
        setLoadingLibraryItems(false)
      })
      .catch((error) => {
        console.error('Error fetching library items for sub-subject:', error)
        setLoadingLibraryItems(false)
      })
  }

  const handleSubjectChange = (value) => {
    setSelectedSubject(value)
    setSelectedSubSubject(null)
    setLibraryItems([])

    const subject = subjects.find((subject) => subject.subjectId === value)
    if (subject && subject.hasSubSubjects) {
      fetchSubSubjects(value)
    } else {
      setSubSubjects([])
      fetchLibraryItemsForSubject(value)
    }
  }

  const handleSubSubjectChange = (value) => {
    setSelectedSubSubject(value)
    fetchLibraryItemsForSubSubject(value)
  }

  const handleDownload = async (event, libraryItemId) => {
    event.preventDefault()
    try {
      const fileUrl = `https://soft.recipe4foodies.com/api/Library/Download-LibraryItem?itemId=${libraryItemId}`
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const blob = await response.blob()
      saveAs(blob)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Download Link',
      dataIndex: 'libraryItemId',
      key: 'libraryItemId',
      render: (libraryItemId) => (
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center',
          }}
        >
          <FilePdfFilled
            style={{ fontSize: '24px' }}
            onClick={(event) => handleDownload(event, libraryItemId)}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <h5>Syllabus</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="subjects" className="form-label" style={{ fontSize: 'large' }}>
                      Subjects
                    </label>
                    <Select
                      id="subjects"
                      style={{ width: '100%' }}
                      placeholder="Select a subject"
                      onChange={handleSubjectChange}
                      loading={loadingSubjects}
                    >
                      {subjects.map((subject) => (
                        <Option key={subject.subjectId} value={subject.subjectId}>
                          {subject.subjectName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {subSubjects.length > 0 && (
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="subsubjects"
                        className="form-label"
                        style={{ fontSize: 'large' }}
                      >
                        Sub-Subjects
                      </label>
                      <Select
                        id="subsubjects"
                        style={{ width: '100%' }}
                        placeholder="Select a sub-subject"
                        onChange={handleSubSubjectChange}
                        loading={loadingSubSubjects}
                      >
                        {subSubjects.map((subSubject) => (
                          <Option key={subSubject.subSubjectId} value={subSubject.subSubjectId}>
                            {subSubject.subSubjectName}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    {loadingLibraryItems ? (
                      <Spin />
                    ) : (
                      <Table
                        dataSource={libraryItems}
                        columns={columns}
                        rowKey="libraryItemId"
                        pagination={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
