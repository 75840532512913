import React from 'react'
import 'antd/dist/antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './meeting.scss'

import { CCardBody } from '@coreui/react'
import { Link } from 'react-router-dom'

const Meetings = () => {
  return (
    <>
      <CCardBody>
        <div className="row mb-3">
          <div className="col-md-3">
            <Link to="/session" className="card-link">
              <div className="newcard">
                <div className="icon-container">
                  <i className="fa fa-video-camera met-icon"></i>
                </div>
                <h3 className="title">Meetings</h3>
                <p className="description mb-4">Schedule Sessions!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/counselorTutor" className="card-link">
              <div className="newcard-counselorTutor">
                <div className="icon-container">
                  <i className="fa fa-users met-icon"></i>
                </div>
                <h3 className="title">Counselor Tutors</h3>
                <p className="description mb-4">Find Guidance!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/newStudents" className="card-link">
              <div className="newcard-newStd">
                <div className="icon-container">
                  <i className="fa fa-users met-icon"></i>
                </div>
                <h3 className="title">New Students</h3>
                <p className="description mb-4">Start Enrollment!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/students" className="card-link">
              <div className="newcard-std">
                <div className="icon-container">
                  <i className="fa fa-users met-icon"></i>
                </div>
                <h3 className="title">Students</h3>
                <p className="description mb-4">View Profile!</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <Link to="/recommendations" className="card-link">
              <div className="newcard-rec">
                <div className="icon-container">
                  <i className="fa fa-handshake-o met-icon"></i>
                </div>
                <h3 className="title">Recommendation</h3>
                <p className="description mb-4">Get Suggestions!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/updateSchedule" className="card-link">
              <div className="newcard-updateSchedule">
                <div className="icon-container">
                  <i className="fa fa-calendar-check-o met-icon"></i>
                </div>
                <h3 className="title">Update Schedule</h3>
                <p className="description mb-4">Modify Timetable!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/syllabus" className="card-link">
              <div className="newcard-syllabus">
                <div className="icon-container">
                  <i className="fa fa-book met-icon"></i>
                </div>
                <h3 className="title">Syllabus</h3>
                <p className="description mb-4">Access Curriculum!</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/intakeInterview" className="card-link">
              <div className="newcard-intakeInterview">
                <div className="icon-container">
                  <i className="fas fa-file met-icon"></i>
                </div>
                <h3 className="title">Intake Interview</h3>
                <p className="description mb-4">Begin Assessment!</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Link to="/requestedSession" className="card-link">
              <div className="newcard-updateSchedule">
                <div className="icon-container">
                  <i className="fas fa-file met-icon"></i>
                </div>
                <h3 className="title">Sessions</h3>
                <p className="description mb-4">Requested Sessions!</p>
              </div>
            </Link>
          </div>
        </div>
      </CCardBody>
    </>
  )
}

export default Meetings
