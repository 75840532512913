import React, { useState, useEffect } from 'react'
import './navbar.scss'
// react-router components
import { useLocation, Link, useNavigate } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @material-ui core components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Icon from '@mui/material/Icon'

import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'
import SoftInput from 'src/components/SoftInput'

import Breadcrumbs from 'src/TutorNavLayouts/Breadcrumbs'
import NotificationItem from 'src/TutorNavLayouts/Items/NotificationItem'
import AccountItems from 'src/TutorNavLayouts/AccountItems/AccountData'

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'src/examples/Navbars/DashboardNavbar/styles'

import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'src/context'

// Images
import team2 from 'src/assets/images/u1.png'
import logoSpotify from 'src/assets/images/p.png'
import changepassword from 'src/assets/images/chp.png'
import logout from 'src/assets/images/logOUT.png'
import pluseorder from 'src/assets/images/plus.png'
import downcloud from 'src/assets/images/clouddown.png'
import warning from 'src/assets/images/warning.jpg'
import complete from 'src/assets/images/complete.png'
import Gscript from 'src/assets/images/script.png'
import { Button } from 'antd'

function DashboardNavbar({ absolute, light, isMini }) {
  const userId = localStorage.getItem('userId') || ''
  const userRole = localStorage.getItem('role')
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller
  const [openMenu, setOpenMenu] = useState(false)
  const [openaccountmenu, setOpenaccountmenu] = useState(false)
  const route = useLocation().pathname.split('/').slice(1)
  const navigate = useNavigate()

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
    }
    window.addEventListener('scroll', handleTransparentNavbar)
    handleTransparentNavbar()
    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget)
  const handleCloseMenu = () => setOpenMenu(false)
  const handleaccount = (event) => setOpenaccountmenu(event.currentTarget)
  const closeaccount = () => setOpenaccountmenu(false)
  const handlecloseaccount = () => {
    localStorage.setItem('logout', true)
    localStorage.clear()
    navigate('/tutorLogin')
  }
  // const handleuploaddocs = () => {
  //   // Navigate to another page when Upload Docs is clicked
  //   navigate('/uploadDocs') // Update with the desired path
  // }
  const handleprofile = () => {
    navigate('/profile')
  }
  const handlechangepassword = () => {
    navigate('/changePassword')
  }

  const backTomultiLogin = () => {
    navigate('/multiLogin')
  }

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'left',
      // }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      // sx={{
      //   maxHeight: '400px',
      //   minHeight: '400px',
      //   overflowY: 'auto',
      //   overflowX: 'hidden',
      // }}
    >
      <NotificationItem
        image={<img src={pluseorder} />}
        title={['', 'You have new order!']}
        date="Are your doing meet me tonight?"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={downcloud} alt="person" />}
        title={['', '99% Server load']}
        date="You got new order of goods."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={warning} alt="person" style={{ height: '36px' }} />}
        title={['', 'Warning notification']}
        date="Server have 99% CPU usage."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={complete} alt="person" />}
        title={['', 'Complete the task']}
        date="Cake sesame snaps cupcake."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={Gscript} alt="person" />}
        title={['', 'Generate monthly report']}
        date="Chocolate cake oat cake tiramisu marzipan."
        onClick={handleCloseMenu}
      />
    </Menu>
  )

  const account = () => (
    <Menu
      anchorEl={openaccountmenu}
      anchorReference={null}
      open={Boolean(openaccountmenu)}
      onClose={closeaccount}
      sx={{ marginLeft: 'auto' }}
    >
      {/* <AccountItems
        image={<img src={team2} style={{ width: '50px', marginLeft: '-7px', marginTop: '-7px' }} />}
        title={['Upload Docs']}
        onClick={handleuploaddocs}
      /> */}
      <AccountItems
        image={<img src={logoSpotify} alt="person" />}
        title={['Profile']}
        onClick={handleprofile}
      />
      <AccountItems
        image={<img src={changepassword} alt="person" />}
        title={['Change Password']}
        onClick={handlechangepassword}
      />
      <AccountItems
        color="secondary"
        image={<img src={logout} alt="person" />}
        title={['Logout']}
        onClick={handlecloseaccount}
      />
    </Menu>
  )

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {userRole === 'Admin' && ( // Conditionally render the button
              <Button
                type="primary"
                onClick={backTomultiLogin}
                ghost
                style={{ marginRight: '10px' }}
              >
                Back to MultiLogin
              </Button>
            )}

            {userRole === 'Tutor' && ( // Conditionally render the Tutor ID
              <SoftBox pr={1}>
                <span style={{ fontSize: '18px' }}>Tutor ID: {userId}</span>
              </SoftBox>
            )}

            <IconButton
              size="small"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={light ? 'text-white' : 'text-dark'}>
                {miniSidenav ? 'menu_open' : 'menu'}
              </Icon>
            </IconButton>
            {renderMenu()}
            <SoftBox color={light ? 'white' : 'inherit'}>
              <IconButton sx={navbarIconButton} size="large" onClick={handleaccount}>
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  account_circle
                </Icon>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? 'white' : 'dark'}
                ></SoftTypography>
              </IconButton>
              {account()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
}

export default DashboardNavbar
