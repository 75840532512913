import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import { useNavigate } from 'react-router-dom'

import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import MiniStatisticsCard from 'src/examples/Cards/StatisticsCards/MiniStatisticsCard/index'

highcharts3d(Highcharts)

const OneOnOneStudent = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const navigate = useNavigate()
  const [improvingCount, setImprovingCount] = useState(0)
  const [sustainingCount, setSustainingCount] = useState(0)
  const [decliningCount, setDecliningCount] = useState(0)

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/StudentProgress/Counselor?counselorId=${userId}`,
        { headers: headers },
      )
      const students = response.data

      const improving = students.filter((student) => student.status === 'Improving').length
      const sustaining = students.filter((student) => student.status === 'Sustaining').length
      const declining = students.filter((student) => student.status === 'Declining').length

      setImprovingCount(improving)
      setSustainingCount(sustaining)
      setDecliningCount(declining)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const pieChartOptions = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
      },
    },
    title: {
      text: '1 on 1 Student Details',
      align: 'left',
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.name}</b>: ${this.point.y}`
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
        showInLegend: false,
        point: {
          events: {
            click: function () {
              const pointName = this.name.toLowerCase()
              switch (pointName) {
                case 'improving':
                  navigate('/1on1Improvingstudents')
                  break
                case 'sustaining':
                  navigate('/1on1sustainingstudents')
                  break
                case 'declining':
                  navigate('/1on1decliningstudents')
                  break
                default:
                  break
              }
            },
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Share',
        data: [
          { name: 'Improving', y: improvingCount },
          { name: 'Sustaining', y: sustainingCount },
          { name: 'Declining', y: decliningCount },
        ],
      },
    ],
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Improving Students' }}
                count={improvingCount}
                icon={{ color: 'info', component: 'people' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Sustaining Students' }}
                count={sustainingCount}
                icon={{ color: 'info', component: 'people' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Declining Students' }}
                count={decliningCount}
                icon={{
                  color: 'info',
                  component: 'people',
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
    </DashboardLayout>
  )
}

export default OneOnOneStudent
